<template>
  <div class="menu-edit-page w-100">
    <basic-subheader title="Tạo mới khảo sát">
      <template v-slot:actions>
        <b-button class="btn ml-3" type="button" @click="returnPage">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Arrows/arrow-left.svg" />
          </span>
          Huỷ
        </b-button>
        <b-button
          class="btn btn-success ml-3"
          type="button"
          :disabled="loading"
          @click="handleValidate"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu
        </b-button>
      </template>
    </basic-subheader>
    <b-container fluid class="menu-edit-page__body mb-6 mt-6">
      <b-overlay :show="loading">
        <SurveyForm ref="form" :data="surveyDetail" is-copy @submit="submit" />
      </b-overlay>
    </b-container>
  </div>
</template>
<script>
import {
  CREATE_SURVEY,
  RESET_STATE_SURVEY,
  GET_SURVEY_DETAIL,
} from '@/core/services/store/survey.module';
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapMutations, mapState } =
  createNamespacedHelpers('survey');
export default {
  name: 'SurveyCreate',
  components: {
    SurveyForm: () => import('./components/surveyForm.vue'),
  },
  data() {
    return {
      loading: false,
    };
  },
  beforeDestroy() {
    this.RESET_STATE_SURVEY();
  },
  computed: {
    ...mapState(['surveyDetail']),
    surveyId() {
      return this.$route.query?.surveyId || null;
    },
  },
  created() {
    this.surveyId && this.getSurveyDetail();
  },
  methods: {
    ...mapActions({ CREATE_SURVEY, GET_SURVEY_DETAIL }),
    ...mapMutations({ RESET_STATE_SURVEY }),
    returnPage() {
      this.$router.go(-1);
    },
    handleValidate() {
      this.$refs.form.handleValidate();
    },
    async getSurveyDetail() {
      this.loading = true;
      await this.GET_SURVEY_DETAIL(this.surveyId);
      this.loading = false;
    },
    async submit(params) {
      this.loading = true;
      let fileId = null;
      if (params.file) {
        // upload file
        const { error, data } = await this.$store.dispatch(
          'context/UPLOAD_IMAGE',
          params.file,
        );
        if (error) {
          this.loading = false;
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error,
          });
        }

        fileId = data.id;
      }

      if (fileId) {
        params['coverId'] = fileId;
        delete params.file;
      }

      // create survey
      const { error } = await this.CREATE_SURVEY(params);
      if (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } else {
        this.returnPage();
      }
      this.loading = false;
    },
  },
};
</script>
<style lang=""></style>
